var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"print",staticClass:"pa-10",attrs:{"id":"damagedDeliveyPage"}},[_c('v-app-bar',{attrs:{"app":""}},[_c('v-toolbar-title',{attrs:{"color":"error"}},[_vm._v("كشف توزيع التالف")]),_c('v-spacer')],1),(_vm.checkPermission('damaged_delivery_add'))?_c('v-card',{staticClass:"pa-10 deliveryStatusTable"},[_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"items":_vm.deliveries,"item-text":"username","item-value":"idUser","outlined":"","hide-details":"","dense":"","label":"الموزعين","multiple":""},model:{value:(_vm.selectedDeliveries),callback:function ($$v) {_vm.selectedDeliveries=$$v},expression:"selectedDeliveries"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"التاريخ","type":"date","outlined":"","hide-details":"","dense":""},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"dark":"","color":_vm.$background},on:{"click":function($event){return _vm.startMethod(0)}}},[_vm._v(" اضافة كشف توزيع تالف جديد ")])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"dark":"","color":_vm.$background},on:{"click":function($event){return _vm.startMethod(1)}}},[_vm._v(" اضافة كشف مالية تالف جديد ")])],1)],1)],1):_vm._e(),_c('br'),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('v-card',{staticClass:"pa-10 deliveryStatusTable"},[_c('h3',[_vm._v("كشف التوزيع للمواد التالفة")]),_c('v-data-table',{attrs:{"items":_vm.deliveriesStatus,"headers":_vm.tableHeader},scopedSlots:_vm._u([{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.sum(item))+" ")]}},{key:"item.invoices",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.invoices.length)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","elevation":"0","block":"","small":"","to":'/damagedDelivery/' + item.idDeliveryStatus}},[_vm._v(" مشاهدة كشف التوزيع ")]),_c('v-btn',{attrs:{"color":"success","elevation":"0","block":"","small":"","to":'/damagedDelivery/' + item.idDeliveryStatus + '?print=true'}},[_vm._v(" طباعة كشف التوزيع ")]),_c('v-btn',{attrs:{"color":"secondary","block":"","elevation":"0","small":"","to":'/print/damagedInvoice/' +
                JSON.stringify(item.invoices).slice(1, -1)}},[_vm._v(" طباعة الفواتير ")]),_c('v-btn',{attrs:{"color":"error","block":"","elevation":"0","small":"","to":'/print/damagedDelegate/' +
                JSON.stringify(item.delegates).slice(1, -1) +
                '/' +
                item.creationFixedDate +
                '?print=1'}},[_vm._v(" طباعة كشف مبالغ التالف ")])]}}],null,true)})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-card',{staticClass:"pa-5 deliveryStatusTable"},[_c('h3',[_vm._v("كشف المالية للمواد التالفة")]),_c('v-data-table',{attrs:{"items":_vm.deliveriesStatusMoney,"headers":_vm.tableHeaderMoney},scopedSlots:_vm._u([{key:"item.total",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.sum(item))+" ")]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-btn',{attrs:{"color":"error","elevation":"0","small":"","block":"","to":'/damagedMoney/' + item.idDeliveryStatus}},[_vm._v(" مشاهدة كشف المالية ")]),_c('v-btn',{attrs:{"color":"warning","elevation":"0","small":"","block":"","to":'/damagedMoney/' + item.idDeliveryStatus + '?print=true'}},[_vm._v(" طباعة كشف المالية ")]),_c('v-btn',{attrs:{"color":"secondary","block":"","elevation":"0","small":"","to":'/print/damagedInvoice/' +
                JSON.stringify(item.invoices).slice(1, -1)}},[_vm._v(" طباعة الفواتير ")])]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }